import AOS from 'aos';
import 'aos/dist/aos.css';

import EmblaCarousel from "embla-carousel";

AOS.init();

let animateImage = document.getElementsByClassName("slider-slide-image");
const carouselOptions = {
    loop: false,
    axis: 'x',
}
const emblaNode = document.querySelector('.main-carousel')
const mainCarousel = EmblaCarousel(emblaNode, carouselOptions)
mainCarousel.on('slidesInView', function () {
    if (mainCarousel.selectedScrollSnap() > 0) {
        // remove aos animations when slide is out of view
        for (let i = 0; i < animateImage.length; i++) {
            animateImage[i].className = animateImage[i].className.replace(
                " aos-animate",
                ""
            );
        }
    } else {
        // run aos animations when slide 0 is selected.
        for (let i = 0; i < animateImage.length; i++) {
            animateImage[i].className = animateImage[i].className += " aos-animate";
        }
    }
});
window.changeSlide = (n) => {
    mainCarousel.scrollTo(n-1, 800);
}
