let modal = document.getElementById("modal-menu");
let toggleBtn = document.getElementById("toggle-btn");
let close = document.getElementsByClassName("close")[0];
let menuItems = document.getElementById("menu-items");

toggleBtn.onclick = function () {
    modal.style.display = "flex";
};
menuItems.onclick = function () {
    window.screen.width < 768
        ? (modal.style.display = "none")
        : (modal.style.display = "flex");
};
close.onclick = function () {
    modal.style.display = "none";
};

window.onclick = function (event) {
    if (event.target == modal) {
        modal.style.display = "none";
    }
};
